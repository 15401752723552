<template>
  <div>
    <div v-if="script">
      <h3>{{ $t("environments.terms.scoped_variables") }}</h3>
      <json-editor key="scoped-variables" v-model="json" :modes="[ 'code', 'tree', 'preview' ]" :height="300" />
      <div class="text-right">
        <b-button class="mt-50" variant="success" size="sm" @click="save">{{ $t("common.terms.save") }}</b-button>
      </div>
    </div>
    <div style="height: 370px;" v-else>
      <b-overlay
        show
        variant="transparent"
        :opacity="0.85"
        blur="2px"
        rounded="sm"
      >
        <h3>{{ $t("environments.terms.scoped_variables") }}</h3>
        <json-editor key="scoped-variables-load" v-model="json" :modes="[ 'code', 'tree', 'preview' ]" :height="300" />
      </b-overlay>
    </div>
  </div>
</template>

<script>
import JsonEditor from "./../JsonEditor/JsonEditor.vue";
import { makeToast } from "@/layouts/components/Popups.js";

import {
  BButton,
  BOverlay,
  BCard,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    JsonEditor,
    BButton,
    BOverlay,
    BCard,
    BCardText
  },
  props: {
    scope: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      json: {},
      originalJson: {},
      script: undefined,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    transmissionID() {
      return this.$route.params.transmissionID
    }
  },
  methods: {
    init() {
      this.loadEnvironment();
    },
    loadEnvironment() {
      const env = JSON.parse(localStorage.getItem(`currentEnv-${this.transmissionID}`));
      this.$store
        .dispatch("getEnvironmentById", { envID: env.id, transmissionID: this.transmissionID })
        .then((response) => {
          let json = response.vars instanceof Array ? {} : response.vars;
          const or_json = response.vars instanceof Array ? {} : response.vars;
          this.originalJson = or_json;

          this.script = response;

          json = json?.[this.scope] || {};
          json = json?.[this.identifier] || {};
          
          this.json = json;
        })
        .catch((err) => {
          console.error(err)
          makeToast({
            title: this.$t("environments.toast.get_by_id.title"),
            text: this.$t("environments.toast.get_by_id.message"),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    save() {
      let final_json = this.originalJson;
      if (!final_json){
        final_json = {}
      }
      final_json[this.scope] = final_json[this.scope] || {};
      final_json[this.scope][this.identifier] = this.json;
  
      const env = JSON.parse(localStorage.getItem(`currentEnv-${this.transmissionID}`));
      this.$store.dispatch("setLoadingState", true)
      this.script.vars = final_json;
      this.$store
        .dispatch("importEnvironmentVars", {
          script: this.script,
          environment: env,
          transmissionID: this.transmissionID
        })
        .then(() => {
          this.$store.dispatch("setLoadingState", false)
          makeToast({
            title: this.$t("environments.toast.scoped_variables.success.title"),
            text: this.$t("environments.toast.scoped_variables.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });
          return true;
        })
        .catch(() => {
          this.$store.dispatch("setLoadingState", false)
          makeToast({
            title: this.$t("environments.toast.scoped_variables.error.title"),
            text: this.$t("environments.toast.scoped_variables.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
          return false;
        });
    }
  },
};
</script>

<style>
</style>